<template>
  <div id="mainPage">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'appMain'
}
</script>

<style scoped>
#mainPage {
  width: calc(100vw - 200px);
  height: calc(100vh - 65px);
  box-sizing: border-box;
  padding: 20px;
  box-sizing: border-box;
}
</style>
