<template>
  <div class="layoutContainer">
    <topBar/>
    <div class="mainContainer">
      <navAside/>
      <div class="mainView">
        <el-scrollbar>
          <appMain/>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/layout/topBar'
import navAside from '@/components/layout/navAside'
import appMain from '@/components/layout/appMain'
export default {
  name: 'indexLayout',
  components: { topBar, navAside, appMain }
}
</script>

<style lang="scss" scoped>
.layoutContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .mainContainer {
    height: calc(100vh - 65px);
    display: flex;
    .mainView {
      height: calc(100vh - 65px);
      flex-shrink: 0;
    }
  }
}
</style>
