<template>
  <div class="column-bar-container">
<!--    <el-scrollbar style="width: 200px">-->
      <el-menu
              style="
             min-width: 200px;
              height: 100%;
              box-sizing: border-box;
              padding-bottom: 80px;
              overflow: hidden;
            "
              background-color="#282c34"
              text-color="#fff"
              active-text-color="#ffd04b"
              :default-active="routerIndex"
            >
              <template v-for="(item, index) in tabList">
                <template v-if="!item.meta.hidden && item.meta.show">
                  <el-submenu v-if="item.children.length > 0" :index="'' + index" :key="item.path">
                    <template slot="title">
                      <i
                        :class="item.meta.icon ? item.meta.icon : 'el-icon-location'"
                      ></i>
                      <span>{{ item.meta.title }}</span>
                    </template>
                    <template v-for="(child, childIndex) in item.children">
                      <el-menu-item
                        v-if="!child.meta.hidden"
                        :index="index + '-' + childIndex"
                        @click="changeRouter(child.fullpath)"
                        :key="child.path"
                      >
                        <i
                          :class="child.meta.icon ? child.meta.icon : 'el-icon-location'"
                        ></i>
                        <span>{{ child.meta.title }}</span>
                      </el-menu-item>
                    </template>
                  </el-submenu>
                  <el-menu-item
                    v-else
                    :index="'' + index"
                    @click="changeRouter(item.path)"
                    :key="item.path"
                  >
                    <i
                      :class="item.meta.icon ? item.meta.icon : 'el-icon-location'"
                    ></i>
                    <span>{{ item.meta.title }}</span>
                  </el-menu-item>
                </template>
              </template>
            </el-menu>
<!--    </el-scrollbar>-->
  </div>
</template>

<script>
export default {
  name: 'navAside',
  data () {
    return {
      routerIndex: '0-0',
      tabList: [],
      role: ''
    }
  },
  watch: {
    $route: {
      handler (route) {
        this.setRouterIndex()
      },
      immediate: true
    }
  },
  created () {
    const list = this.$router.options.routes
    const role = JSON.parse(localStorage.getItem('userInfo')).role[0]
    list.forEach((item) => {
      item.meta.show = item.meta.role.some(item => { return item === role })
      if (item.children.length > 0) {
        item.children.forEach((childItem) => {
          childItem.fullpath =
            item.path === '/'
              ? item.path + childItem.path
              : item.path + '/' + childItem.path
        })
      }
    })
    this.tabList = list
    this.role = role
  },
  mounted () {
    this.setRouterIndex()
  },
  methods: {
    setRouterIndex () {
      const route = this.$route
      const list = this.tabList
      list.forEach((item, index) => {
        if (item.children.length > 0) {
          item.children.forEach((childItem, childindex) => {
            if (route.fullPath === childItem.fullpath) {
              this.routerIndex = index + '-' + childindex
            }
          })
        } else {
          if (route.fullPath === item.path) {
            this.routerIndex = index
          }
        }
      })
    },
    changeRouter (path) {
      if (path === this.$route.path) {
        return false
      } else {
        this.$router.push(path)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.column-bar-container {
  height: calc(100vh - 65px);
  background: #282c34;
  width: 200px;
  overflow: hidden;
}
</style>
