<template>
  <div class="topBar" style="-webkit-app-region: drag">
    <div class="topLeft">
<!--      <img class="logo" alt="logo" src="@/assets/logo.png" />-->
    </div>
    <div class="topCenter">
    </div>
    <div class="topRight">
      <el-badge is-dot>
        <i class="el-icon-message-solid icon" style="cursor: pointer"/>
      </el-badge>
      <el-popover
        placement="bottom"
        trigger="click"
        width="150xp"
        >
        <div class="userFuc" @click="logout">登出</div>
        <el-avatar class="avatar" slot="reference" size="large" style="cursor: pointer" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"/>
      </el-popover>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
export default {
  name: 'topBar',
  data () {
    return {
      visible: false
    }
  },
  methods: {
    logout () {
      Cookies.remove('token')
      localStorage.removeItem('userInfo')
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.userFuc {
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.topBar{
  width: 100vw;
  height: 65px;
  background: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  padding-left: 15px;
  box-sizing: border-box;
  z-index: 300;
  .topLeft {
    width: 220px;
    height: 50px;
    .logo {
    //width: 220px;
      height: 50px;
      object-fit: contain;
    }
  }
  .topCenter {
    width: calc(100% - 570px);
  }
  .topRight {
    width: 350px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .avatar {
      margin-left: 60px;
    }
    .icon {
      font-size: 26px;
      color: #909399;
    }
    .icon:hover {
      color: #a6a9ad;
    }
  }
}
</style>
