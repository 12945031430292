import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Root',
    component: Layout,
    redirect: '/index',
    meta: {
      hidden: false,
      title: '首页',
      icon: 'el-icon-house',
      role: ['admin', 'superAdminLower', 'adminLower', 'guide', 'work']
    },
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import('../views/index/index'),
        meta: {
          hidden: false,
          title: '概要',
          icon: 'el-icon-s-data',
          role: ['admin', 'superAdminLower', 'adminLower', 'guide', 'work']
        }
      },
      {
        path: 'serverStatus',
        name: 'ServerStatus',
        component: () => import('../views/index/serverStatus'),
        meta: {
          hidden: false,
          title: '服务器状态',
          icon: 'el-icon-coin',
          role: ['admin', 'superAdminLower', 'adminLower', 'guide', 'work']
        }
      },
      {
        path: 'networkStatus',
        name: 'NetworkStatus',
        component: () => import('../views/index/networkStatus'),
        meta: {
          hidden: false,
          title: '网络',
          icon: 'el-icon-discover',
          role: ['admin', 'superAdminLower', 'adminLower', 'guide', 'work']
        }
      }
    ]
  },
  {
    path: '/systemManage',
    name: 'SystemManage',
    component: Layout,
    meta: {
      hidden: false,
      title: '系统管理',
      icon: 'el-icon-monitor',
      role: ['admin', 'superAdminLower']
    },
    children: [
      {
        path: 'adminAccount',
        name: 'AdminAccount',
        component: () => import('../views/systemManagementSuperAdmin/adminAccount'),
        meta: {
          hidden: false,
          title: '管理员账号',
          icon: 'el-icon-s-custom',
          role: ['admin', 'superAdminLower']
        }
      },
      {
        path: 'superAdminLogManage',
        name: 'SuperAdminLogManage',
        component: () => import('../views/systemManagementSuperAdmin/logManagement'),
        meta: {
          hidden: false,
          title: '日志管理',
          icon: 'el-icon-notebook-2',
          role: ['admin', 'superAdminLower']
        }
      }
    ]
  },
  {
    path: '/calling',
    name: 'Calling',
    component: Layout,
    meta: {
      hidden: false,
      title: '通话管理',
      icon: 'el-icon-mic',
      role: ['admin', 'adminLower']
    },
    children: [
      {
        path: 'currentCalls',
        name: 'CurrentCalls',
        component: () => import('../views/callManagementAdmin/currentCalls'),
        meta: {
          hidden: false,
          title: '当前通话',
          icon: 'el-icon-phone-outline',
          role: ['admin', 'adminLower']
        }
      },
      {
        path: 'callRecordings',
        name: 'CallRecordings',
        component: () => import('../views/callManagementAdmin/callRecordings'),
        meta: {
          hidden: false,
          title: '通话录音',
          icon: 'el-icon-video-play',
          role: ['admin', 'adminLower']
        }
      },
      {
        path: 'callingDetails',
        name: 'CallingDetails',
        component: () => import('../views/callManagementAdmin/callingDetails'),
        meta: {
          hidden: false,
          title: '通话详情',
          icon: 'el-icon-notebook-2',
          role: ['admin', 'adminLower']
        }
      }
    ]
  },
  {
    path: '/systemManager',
    name: 'SystemManger',
    component: Layout,
    meta: {
      hidden: false,
      title: '系统管理',
      icon: 'el-icon-monitor',
      role: ['admin', 'adminLower']
    },
    children: [
      {
        path: 'userAccount',
        name: 'UserAccount',
        component: () => import('../views/systemManagementAdmin/userAccount'),
        meta: {
          hidden: false,
          title: '用户账号',
          icon: 'el-icon-user',
          role: ['admin', 'adminLower']
        }
      },
      {
        path: 'groupManage',
        name: 'GroupManage',
        component: () => import('../views/systemManagementAdmin/groupManage'),
        meta: {
          hidden: false,
          title: '分组管理',
          icon: 'el-icon-user-solid',
          role: ['admin', 'adminLower']
        }
      },
      {
        path: 'roomManage',
        name: 'RoomManage',
        component: () => import('../views/systemManagementAdmin/roomManage'),
        meta: {
          hidden: false,
          title: '房间管理',
          icon: 'el-icon-chat-dot-round',
          role: ['admin', 'adminLower']
        }
      },
      {
        path: 'templateManage',
        name: 'TemplateManage',
        component: () => import('../views/systemManagementAdmin/templateManage'),
        meta: {
          hidden: false,
          title: '模板管理',
          icon: 'el-icon-receiving',
          role: ['admin', 'adminLower']
        }
      },
      {
        path: 'adminLogManage',
        name: 'AdminLogManage',
        component: () => import('../views/systemManagementAdmin/logManagement'),
        meta: {
          hidden: false,
          title: '日志管理',
          icon: 'el-icon-notebook-2',
          role: ['admin', 'adminLower']
        }
      }
    ]
  },
  {
    path: '/panel',
    name: 'Panel',
    component: Layout,
    meta: {
      hidden: false,
      title: '导控面板',
      icon: 'el-icon-menu',
      role: ['admin', 'guide', 'work']
    },
    children: [
      {
        path: 'guidePanels',
        name: 'GuidePanels',
        component: () => import('../views/guidePanels/guidePanels'),
        meta: {
          hidden: false,
          title: '导控面板',
          icon: 'el-icon-menu',
          role: ['admin', 'guide', 'work']
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login'),
    meta: {
      hidden: true,
      title: '登录',
      role: []
    },
    children: []
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
