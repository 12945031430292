import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import feather from 'vue-icon'
import Cookies from 'js-cookie'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(feather, {
  name: 'v-icon',
  data () {
    return {
      baseClass: 'v-icon',
      classPrefix: 'v-icon-'
    }
  }
})

// 路由拦截，判断是否需要登录
router.beforeEach((to, from, next) => {
  console.log(to)
  const token = Cookies.get('token')
  // 若需要登录访问，检查是否为登录状态
  if (!token && to.path !== '/login') {
    next({
      path: '/login'
    })
  } else if (token && to.path !== '/login') {
    const role = JSON.parse(localStorage.getItem('userInfo')).role[0]
    const hasRole = to.meta.role.some(item => { return item === role })
    if (hasRole) {
      next()
    } else {
      next({
        path: '/login'
      })
    }
  } else {
    next()
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
