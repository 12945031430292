import { render, staticRenderFns } from "./navAside.vue?vue&type=template&id=e73a4506&scoped=true&"
import script from "./navAside.vue?vue&type=script&lang=js&"
export * from "./navAside.vue?vue&type=script&lang=js&"
import style0 from "./navAside.vue?vue&type=style&index=0&id=e73a4506&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e73a4506",
  null
  
)

export default component.exports